<template>
  <v-container>
    <perseu-card title="Tipos">
      <template #title-right>
        <v-btn color="secondary" @click="changeOrder" class="mr-3">
          Alterar ordem
        </v-btn>
        <v-btn color="secondary" @click="create"> Novo Tipo</v-btn>
      </template>
      <v-flex slot="content">
        <v-switch v-model="query.active" label="Ativos" />
        <v-data-table :items="types" :headers="headers" class="elevation-1">
          <template v-slot:item.actions="props">
            <v-btn small icon @click="edit(props.item)" color="secondary">
              <v-icon small>edit</v-icon>
            </v-btn>
            <v-btn
              small
              icon
              v-if="props.item.active"
              @click="activeOrDeactivate(props.item, false)"
              color="secondary"
            >
              <v-icon small>delete</v-icon>
            </v-btn>
            <v-btn
              small
              v-else
              icon
              @click="activeOrDeactivate(props.item, true)"
              color="secondary"
            >
              <v-icon small>check</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </perseu-card>
    <form-type ref="form" @update="updatedForm" @create="createdForm" />
  </v-container>
</template>

<script>
import * as service from "@/services/types-service";
import { pagesNames } from "@/router/pages-names";

export default {
  components: {
    "form-type": () => import("@/components/Types/Form"),
  },
  data: () => ({
    headers: [
      { text: "Nome", value: "name" },
      { text: "Slug", value: "slug" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "start",
        width: 200,
      },
    ],
    types: [],
    type: {},
    query: {
      active: true,
    },
  }),
  watch: {
    query: {
      handler() {
        this.list();
      },
      deep: true,
    },
  },
  created() {
    this.list();
  },
  methods: {
    async list() {
      try {
        this.$store.dispatch("loading/openDialog");
        let response = await service.find(this.query);
        this.types = response.data;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    create() {
      this.$refs.form.create();
    },
    edit(obj) {
      this.$refs.form.edit(obj);
    },
    updatedForm(typeParam) {
      const index = this.findIndexInItems(typeParam);
      this.types.splice(index, 1, typeParam);
    },
    createdForm(type) {
      this.types.push(type);
    },
    async activeOrDeactivate(item, target) {
      try {
        this.$store.dispatch("loading/openDialog");
        item.active = target;
        await service.create(item);
        this.removeFromList(item);
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    removeFromList(type) {
      let index = this.types.indexOf(type);
      this.types.splice(index, 1);
    },
    findIndexInItems(typeParam) {
      return this.types.findIndex((type) => type.id === typeParam.id);
    },
    changeOrder() {
      this.$router.push({ name: pagesNames.TYPES_ORDER });
    },
  },
};
</script>

<style></style>
